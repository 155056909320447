import { Box, Typography } from "@material-ui/core"
import { PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import { PageContext } from "../interfaces/pageContext"

export type NotFoundPageProps = PageProps<any, PageContext>

const NotFoundPage: React.FC<NotFoundPageProps> = (props) => {
  const { location, pageContext } = props
  pageContext.title = "Seite nicht gefunden"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Helmet defer={false}>
        <meta name="robots" content={`noindex, noarchive, noimageindex`} />
      </Helmet>
      <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
        <Typography align="center" variant="h1">
          {pageContext.title}
        </Typography>
        <Typography align="center" paragraph={true}>
          Leider existiert diese Seite nicht.
        </Typography>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
